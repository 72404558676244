import { createApp } from 'vue'

import { createPinia } from 'pinia';

import router from './router'; 

import App from './App.vue'

import 'element-plus/dist/index.css';

const app = createApp(App)

// 创建 Pinia 实例
const pinia = createPinia();

// 把 Pinia 注入到 Vue 应用中
app.use(pinia)


app.use(router); // 使用路由


app.mount('#app')

const debounce = (fn, delay) => {
    let timer
     return (...args) => {
       if (timer) {
         clearTimeout(timer)
       }
       timer = setTimeout(() => {
         fn(...args)
       }, delay)
     }
  }
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
     constructor(callback) {
       callback = debounce(callback, 10);
       super(callback);
     }
  }



